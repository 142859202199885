import client from "../../../client";

const Api = {
  // 获取所有租户用户列表
  getShopUserListPaged: "/api/ProductService/ShopUser/GetListPaged",
  // 获取租户用户详情
  getShopUserById: "/api/ProductService/ShopUser/GetById",
  // 登陆记录
  getSafetyList: "/api/URCService/UserLoginLog/GetList",
  //获取所有可用租户用户角色
  getShopRoles: "/api/ProductService/ShopRole/GetShopRoles",
  //获取租户用户角色。
  getRoleIds: "/api/ProductService/ShopUser/GetRoleIds",
  //移除租户用户某角色。
  removeRolesFromUser:"/api/ProductService/ShopUser/RemoveRolesFromUser",
  //给租户用户添加某角色。
  addRolesToUser:"/api/ProductService/ShopUser/AddRolesToUser",
  //租户剔出用户
  cullOutUserFromShop:"/api/ProductService/ShopUser/CullOutUserFromShop",
  //添加租户账号
  createShopUserAccount:"/api/ProductService/ShopUser/CreateShopUserAccount",
  //更改租户角色
  updateShopUserRole:"/api/ProductService/ShopUser/UpdateShopUserRole"
};


export const getShopUserListPaged = (data) => {
  return client.request({
    url: Api.getShopUserListPaged,
    data,
    method: "POST",
  });
};

export const getShopUserById = (data) => {
  return client.request({
    url: Api.getShopUserById,
    params: data,
    method: "GET",
  });
};

export const getSafetyList = (data) => {
  return client.request({
    url: Api.getSafetyList,
    data,
    method: "POST",
  });
};

export const getShopRoles = (data) => {
  return client.request({
    url: Api.getShopRoles,
    data,
    method: "POST",
  });
};

export function getRoleIds(data) {
  return client.request({
    url: Api.getRoleIds,
    params: data,
    method: "GET",
  });
}

export const removeRolesFromUser = (data) => {
  return client.request({
    url: Api.removeRolesFromUser,
    data,
    method: "POST",
  });
};

export const addRolesToUser = (data) => {
  return client.request({
    url: Api.addRolesToUser,
    data,
    method: "POST",
  });
};

export const updateShopUserRole = (data) => {
  return client.request({
    url: Api.updateShopUserRole,
    data,
    method: "POST",
  });
};

export const cullOutUserFromShop = (data) => {
  return client.request({
    url: Api.cullOutUserFromShop,
    data,
    method: "POST",
  });
};

export const createShopUserAccount = (data) => {
  return client.request({
    url: Api.createShopUserAccount,
    data,
    method: "POST",
  });
};

