<template>
  <Content>
    <template v-slot:contentBody>
      <h1>{{ $t("menu.user_account_details") }}</h1>
      <!-- 基本信息 -->
      <div>
        <a-row :gutter="[0, 16]">
          <a-col :xs="24" :sm="21" :md="19" :lg="17" :xxl="8">
            <a-row :gutter="[0, 16]">
              <a-col :span="24">
                <strong>{{ $t("account.essential_information") }}</strong>
              </a-col>
              <a-col :span="5" style="text-align: right">
                
                {{
                $t("account.login_account")
              }}</a-col>
              <a-col :span="17" :offset="1">
                {{ pageInfo.userName }}
                <div>{{pageInfo.nickName}}</div>
                </a-col>
              <a-col :span="5" style="text-align: right">{{
                $t("common.country")
              }}</a-col>
              <a-col :span="17" :offset="1"
                >{{
                  getLanguageName({
                    cnName: pageInfo.countryCnName,
                    enName: pageInfo.countryEnName,
                  })
                }}
              </a-col>
               <a-col :span="5" style="text-align: right">{{
                $t("account.timezone")
              }}</a-col>
              <a-col :span="17" :offset="1"
                >{{ pageInfo.timezone }} 
                </a-col
              >
              
              <a-col :span="5" style="text-align: right">{{
                $t("account.mobile_phone")
              }}</a-col>
              <a-col :span="17" :offset="1"
                >+{{ pageInfo.phoneCountryCode }} {{ pageInfo.phoneNumber }}
                </a-col
              >
              <a-col :span="5" style="text-align: right">{{
                $t("account.secure_mailbox")
              }}</a-col>
              <a-col :span="17" :offset="1">{{ pageInfo.mail || '-' }}</a-col>
              <a-col :span="5" style="text-align: right">{{
                $t("account.registration_date")
              }}</a-col>
              <a-col :span="17" :offset="1">{{
                $filters.utcToCurrentTime(pageInfo.createTime)
              }}</a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>

      <!-- 账号安全 -->
      <div class="mt-3">
        <strong>{{ $t("account.account_security") }}</strong>
        <a-row>
          <a-col :xs="24" :sm="24" :xl="22" :xxl="16">
            <a-table
              class="mt-2"
              :columns="safetyColumns"
              :data-source="safetyList"
              :scroll="{ x: true }"
              :pagination="false"
              :loading="loading"
              size="small"
              :rowKey="
                (record, index) => {
                  return index;
                }
              "
            >
              <template #loginTime="{ record }">
                {{ $filters.utcToCurrentTime(record.loginUtcTime) }}
              </template>
            </a-table>
          </a-col>
        </a-row>
        <a-row type="flex" justify="center" align="middle" class="mt-3">
          <a-col :xs="24" :sm="24" :xl="22" :xxl="16">
            <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
          </a-col>
        </a-row>
      </div>

       <a-row type="flex"
               justify="end"
               class=" mb-5 mt-3">
              <a-col>
              <div style="width: 400px">
                <a-row type="flex" justify="center" :gutter="[24, 12]">
                <a-col class="">
                  <a-button @click="handleBack ">{{$t('common.backtrack')}}</a-button>
                </a-col>
              </a-row>
              </div>
            </a-col>
      </a-row>

    </template>
  </Content>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { Row, Col, Table, Button, message } from "ant-design-vue";
import Content from "@/views/components/Content.vue";
import CPager from "@/views/components/CPager.vue";
import CImage from "@/views/components/CImage.vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { getShopUserById, getSafetyList } from "@/api/modules/user/account";
import { getName } from "@/utils/general";
import { useRoute, useRouter } from "vue-router";
import { useTab } from "@/hooks/tabs/index";

export default defineComponent({
  name: "user_account_details",
  components: {
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AButton: Button,
    Content,
    CImage,
    CPager,
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const route = useRoute();
    const { delVisitedRoute } = useTab();
    const router = useRouter();

    const state = reactive({
      loading: false,
      pageInfo: {
        shopId: "",
        userName: "",
        nickName:"",
        countryCnName: "",
        countryEnName: "",
        timezone:null,
        phoneCountryCode: "",
        phoneNumber: "",
        mail: "",
        createTime: "",
        shopName: "",
        businessLicenseCode: "",
        isLocked: null,
      },
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
      safetyList: [],
    });

    const safetyColumns = [
      {
        title: () => vueI18n.t("account.serial_number"),
        dataIndex: "",
        width: 150,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: () => vueI18n.t("account.login_time"),
        dataIndex: "loginTime",
        slots: {
          customRender: "loginTime",
        },
        width: 300,
      },
      {
        title: "IP",
        dataIndex: "loginIp",
      },
    ];

    const handlePage = async (pageData) => {
      state.pageData.skipCount = pageData.skipCount;
      state.pageData.maxResultCount = pageData.maxResultCount;
      funcGetSafetyList();
    };

    const getLanguageName = (item) => {
      return getName(item, getters.language);
    };

    const funcGetSafetyList = () => {
      const data = Object.assign({}, {
        userIds: [state.userId],
        shopId: state.pageInfo.shopId,
        pageIndex: state.pageData.currentIndex,
        pageSize: state.pageData.maxResultCount,
      });

      getSafetyList(data).then(({ result }) => {
        let { pageData, totalCount } = result;
        if (totalCount > 0) {
          state.safetyList = pageData;
          state.pageData.totalCount = parseInt(totalCount);
        } else {
          state.pageData.totalCount = 0;
          state.safetyList = [];
        }
      });
    };

    const initDate = () => {
      getShopUserById({ userId: state.userId }).then((res) => {
        let { result } = res;
        if (result) {
          for (const key in state.pageInfo) {
            if (Object.hasOwnProperty.call(result, key)) {
              state.pageInfo[key] = result[key];
            }
          }
          funcGetSafetyList();
        } else {
          message.error(vueI18n.t("common.no_data"));
        }
      });
    };

    const handleBack = async (record) => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "consignment_allot_plans" });
    };

    onMounted(() => {
      let id = route.params.id;
      if (id) {
        state.userId = id;
        initDate();
      }
    });

    return {
      ...toRefs(state),
      safetyColumns,
      getLanguageName,
      handlePage,
      handleBack
    };
  },
});
</script>
